<template>
  <div class="home">
    <div class="container">
      <div class="row lists-wrapper">
        <div v-for="appList in appLists" :key="appList.id" class="col col-12 col-lg-6">
          <div class="app-list-container" v-if="appList.items.length > 0">
            <h3>
              {{ appList.title }}
              <router-link class="view-all-link" :to="{name: 'collection', params: { feedKey: appList.id } }">view all</router-link>
            </h3>
            <AppList :apps="appList.items.slice(0, 15)"/>
          </div>
          <div class="loading-spinner-wrapper" v-else>
            <LoadingSpinner/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import iTunes from '@/services/itunes'
import {Circle} from 'vue-loading-spinner'
import AppList from '@/components/AppList.vue'

const itunes = new iTunes();

export default {
  name: 'home',
  components: {
    AppList,
    LoadingSpinner: Circle
  },
  data() {
    return {
      appLists: {}
    }
  },
  mounted() {
    const feedKeys = [
      iTunes.Feed.topFree,
      iTunes.Feed.topPaid,
    ];

    for (let feedKey of feedKeys) {
      const emptyFeed = {
        id: feedKey,
        title: '',
        items: []
      };
      this.$set(this.appLists, feedKey, emptyFeed);

      itunes
        .getFeed(feedKey)
        .then((feed) => {
          this.$set(this.appLists, feedKey, feed);
        });
    }

    
  }
}
</script>

<style lang="scss">
.app-list-container {
  h3 {
    margin-top: 30px;
    margin-bottom: 15px;
  }
}
.lists-wrapper {
  margin: 0 -15px 30px;
}
.loading-spinner-wrapper {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-all-link {
  float: right;
  font-size: 1rem;
  line-height: 2rem;
}
</style>
