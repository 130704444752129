<template>
<swiper :options="swiperOptions" ref="mySwiper">
    <!-- slides -->
    <swiper-slide>
      <div class="helpslide" data-step="1">
        <p class="helpslide__title">Get the companion App</p>
        <a href="https://lets.instl.app/ios" title="Get the companion App">
          <img class="helpslide__img" src="/imgs/appstore-icon-us-white.svg" alt="Download from the App Store">
        </a>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="helpslide" data-step="2">
        <p class="helpslide__title">Bind your device</p>
        <router-link :to="{name: 'bind-device'}" class="helpslide__btn">Bind Device</router-link>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="helpslide" data-step="3">
        <p class="helpslide__title">Click the INSTL button</p>
        <img class="helpslide__img" src="/imgs/instl-btn.svg" alt="Download from the App Store">
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="helpslide" data-step="4">
        <p class="helpslide__title">Tap the notification on your device</p>
        <img class="helpslide__img" src="/imgs/appicon.svg" alt="Download from the App Store">
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
import 'swiper/dist/css/swiper.css'
 
import { swiper, swiperSlide } from 'vue-awesome-swiper'
 
export default {
  components: {
    swiper,
    'swiper-slide': swiperSlide
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 2,
        autoplay: {
          delay: 3000
        },
        breakpoints: {
          575: {
            slidesPerView: 1
          },
          1199: {
            slidesPerView: 2,
            spaceBetween: 30
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
.helpslide {
  background-image: linear-gradient(0deg, #2064F0 0%, #20D8FD 100%);
  color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 15px;
  padding-top: 0;
  border-radius: 0 0 15px 15px;
  height: 220px;

  @media (min-width: 1200px) {
    border-radius: 0;

    &[data-step="1"] {
      border-radius: 0 0 0 15px;
    } 

    &[data-step="4"] {
      border-radius: 0 0 15px 0;
    } 
  }

  &__title {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2rem;
    margin: 1rem 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &__img, &__btn {
    height: 44px;
    line-height: 44px;
  }

  &__btn {
    background: #fff; 
    text-decoration: none;
    font-weight: bold;
    padding: 0 10px;
    border-radius: 5px;

    &:hover {
      text-decoration: none;
    }
  }

  &::before {
    content: attr(data-step);
    width: 84px;
    background: #fff;
    line-height: 42px;
    border-radius: 0 0 42px 42px;
    color: #20D8FD;
    font-weight: bold;
    font-size: 1.5rem;
  }
}
</style>
