<template>
  <div class="instl-btn-wrapper">
    <button 
      @click="installApp" 
      target="_blank" 
      class="instl-btn" :class="status">
        <span>{{ isIdle ? btnText : '&nbsp;' }}</span>
      </button>
    <a 
      class="direct-link" 
      :href="appViewUrl" 
      target="_blank"
      v-if="showDirectUrl && appViewUrl">view</a>
  </div>
</template>

<script>
import InstlCore from '@/services/instl-core.js'

const instl = InstlCore.getInstance();

export default {
  props: {
    app: {
      type: Object
    },
    showDirectUrl: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      status: 'idle'
    }
  },
  computed: {
    appId() {
      return this.app.trackId || this.app.id;
    },
    appName() {
      return this.app.trackName || this.app.name;
    },
    appLogo() {
      return this.app.artworkUrl100;
    },
    appViewUrl() {
      return this.app.trackViewUrl || this.app.url;
    },
    btnText() {
      return 'INSTL';
    },
    isLoading() {
      return this.status === 'loading';
    },
    isIdle() {
      return this.status === 'idle';
    }
  },
  methods: {
    setError(msg) {
      this.status = 'error';
      this.$notify({
        title: 'Error',
        text: msg,
        type: 'error',
        duration: 8000
      });
      setTimeout(() => {
        this.status = 'idle';
      }, 3000);
    },
    installApp() {
      this.status = 'loading';
      instl
        .installApp(this.appId, this.appName, this.appLogo)
        .then((data) => {
          this.status = 'success';
        })
        .catch((e) => {
          this.setError(e.message);
        });
    }
  }
}
</script>


<style lang="scss">
.instl-btn-wrapper {
  position: relative
}

.instl-btn {
  position: relative;
  background: #007AFF;
  color: #fff;
  border-radius: 1.6rem;
  line-height: 1.6rem;
  display: inline-block;
  padding: 0 10px;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  -webkit-appearance: none;
  border:none;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}
.direct-link {
  position: absolute;
  color: rgb(71, 102, 136);
  font-size: 0.75rem;
  line-height: 0.75rem;
  width: 100%;
  bottom: -1rem;
  left: 0;
  text-align: center;

  &:hover {
    text-decoration: none;
  }
}
.instl-btn {
  transition: background-color 0.3s, width 0.3s;

  &:not(.idle){ 
    margin: 0 1rem;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      top: 2px;
      left: 2px;
      border-radius: 100%;
      line-height: 0.9rem;
      font-size: 0.7rem;
    }
  }

  &.loading {
    &::after {
      border: 2px dotted #fff;
      animation: rotation 1.5s infinite;
    }
  }

  // &.error, &.success { }

  &.error {
    background: rgb(201, 0, 97);
    // &::after { }
  }

  &.success {
    background: rgb(0, 201, 97);

    // &::after { }
  }
}

@keyframes rotation {
  0% { transform: rotate(0) }
  100% { transform: rotate(359.99deg) }
}
</style>

