<template>
  <div id="app">
    <header class="top-bar">
      <div class="container">
        <div class="row">
          <div class="col col-lg-4 d-none d-lg-block">
            <nav class="topnav">
              <router-link :to="{name: 'home'}">Home</router-link>
              <router-link :to="{name: 'bind-device'}">Bind device</router-link>
              <router-link :to="{name: 'collection', params: {feedKey: 'top-free'}}">Top free</router-link>
              <router-link :to="{name: 'collection', params: {feedKey: 'top-paid'}}">Top paid</router-link>
            </nav>
          </div>
          <div class="col col-lg-4 text-center">
            <router-link :to="{name: 'home'}">
              <figure class="app-logo-wrapper">
                <img class="app-logo" src="/instl.app-logo.svg">
              </figure>
            </router-link>
          </div>
          <div class="col col-lg-4 d-none d-lg-block">
            <form @submit="search" class="search-bar">
              <input type="search" v-model="query" placeholder="Search..." class="search-bar__input">
              <button type="submit" class="search-bar__btn">
                <img src="/imgs/search-icn.svg" alt="search">
              </button>
            </form>
          </div>
        </div>
      </div>
    </header>

    <div class="container">
      <div class="row">
        <div class="col">
          <HelpBanner/>
        </div>
      </div>
    </div>

    <router-view/>

    <footer class="main-footer">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-1"></div>
          <div class="col">
            <p>
              Instl.app by Fausto Ristagno P.IVA IT09648900968 | <a href="mailto:info@doublerew.net">info@doublerew.net</a> | See more at <a href="https://doublerew.net">DoubleREW.net</a>
            </p>
            <p>
              <router-link :to="{name: 'legal', params: {contentName: 'privacy'}}">Privacy Policy</router-link> |
              <router-link :to="{name: 'legal', params: {contentName: 'cookie'}}">Cookie Policy</router-link> |
              <router-link :to="{name: 'legal', params: {contentName: 'terms'}}">Terms of Use</router-link>  |
              <router-link :to="{name: 'login'}">Login</router-link> 
            </p>
          </div>
          <div class="col-md-1">
            <router-link :to="{name: 'country-selector'}">
              <img class="current-country" :src="currentCountryStore ? currentCountryStore.flag : ''">
            </router-link>
          </div>
        </div>
      </div>
    </footer>

    <div class="notifications">
      <notifications position="bottom right" />
    </div>

    <cookie-law theme="blood-orange"></cookie-law>
  </div>
</template>

<script>
import HelpBanner from '@/components/HelpBanner.vue'
import CookieLaw from 'vue-cookie-law'
import iTunes from '@/services/itunes'
const itunes = new iTunes();


export default {
  components: {
    HelpBanner,
    CookieLaw
  },
  data() {
    return {
      query: '',
      currentCountryStore: null
    }
  },
  computed: {
  },
  methods: {
    search(e) {
      e.preventDefault();
      e.stopPropagation();

      if (this.query && this.query.length > 2) {
        this.$router.push({ name: 'search', query: {q: this.query} });
      }
    }
  },
  watch: {
    '$route.query.q': function (q) {
      this.query = q;
    }
  },
  mounted() {
    this.currentCountryStore = itunes.storeCountry;
    this.$root.$on('country-changed', () => {
      this.currentCountryStore = itunes.storeCountry;
    });
  }
}
</script>


<style lang="scss">
body, html {
  margin: 0;
  padding: 0;
  background: #F7F7F7;
  font-size: 14px;
}
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}
h1 {
  margin-top: 2rem;
}
#app {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212121;
  padding-top: 64px;
}

.top-bar {
  padding: 15px;
  color: #007AFF;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);

  a {
    line-height: 32px;
    font-weight: bold;
    color: #007AFF;
    &.router-link-exact-active {
      color: rgb(0, 66, 136);
    }
  }
}
.app-logo-wrapper { 
  position: relative;
  margin-bottom: 0;
  display: inline-block;

  &::after {
    content: "(beta)";
    text-decoration: none !important;
    position: absolute;
    bottom: -15px;
    right: -12px;
    font-size: 0.5625rem;
    color: #007AFF !important;
  }
}
.app-logo {
  height: 32px;
}
.topnav {
  overflow: hidden;
  white-space: nowrap;

    a {
      padding: 0 10px;

      &:first-child {
        padding-left: 0;
      }
  }
}
.search-bar {
  flex-direction: row;
  justify-content: center;
  position: relative;
  max-width: 280px;
  margin: 0 0 0 auto;

  &__input {
    display: inline-block;
    width: 100%;
    -webkit-appearance: none;
    border-radius: 4px;
    // border: none;
    border: 1px solid #ddd;
    height: 32px;
    line-height: 32px;
    background: rgba(0, 122, 255, 0.0);
    padding: 0 32px 0 10px;
    text-align: left;
    color: #007AFF;
    transition: background-color 0.3s;

    &:hover, &:focus {
      border: 1px solid #007AFF;
      background: rgba(0, 122, 255, 0.05);
    }
  }

  &__btn {
    display: inline-block;
    position: absolute;
    -webkit-appearance: none;
    background: none;
    border: none;
    right: 0;
    height: 100%;
    color: #007AFF;
    border-radius: 44px;
    width: 45px;
    padding: 4px;
    cursor: pointer;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.page-title {
  margin: 30px 0 15px;
}

@media (min-width: 1400px) {
  .container {
      max-width: 1340px;
  }
}

.current-country {
  height: 30px;
}
.box-wrapper {
  background: #fff;
  border-radius: 4px;
  margin: 30px 15px;
  padding: 15px;
}
.main-footer {
  text-align: center;
  background: #6c7e93;
  color: #fff;
  font-size: 0.8rem;
  border-top: 2px solid #007AFF;

  p:first-child {
    margin-top: 1rem;
  }

  a:link, a:visited, a:active {
    color: #ddd;
  }

  .current-country {
    margin: 1rem 0;
  }
}
</style>
