import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/search',
      name: 'search',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "search" */ './views/Search.vue')
    },
    {
      path: '/collection/:feedKey',
      name: 'collection',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "collection" */ './views/Collection.vue')
    },
    {
      path: '/bind',
      name: 'bind-device',
      component: () => import(/* webpackChunkName: "bind-device" */ './views/BindDevice.vue')
    },
    {
      path: '/country',
      name: 'country-selector',
      component: () => import(/* webpackChunkName: "country-selector" */ './views/CountrySelector.vue')
    },
    {
      path: '/legal/:contentName',
      name: 'legal',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "legal" */ './views/Legal.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
  ]
})
