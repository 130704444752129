<template>
  <div class="app-row no-gutters row" :class="showBottomSeparator ? 'app-row--has-separator' : ''">
    
    <div class="col-auto app-row__img-wrapper">
      <img v-lazy="app.artworkUrl100" alt="" class="app-row__img">
    </div>
    
    <div class="col app-row__info-wrapper">
      <h4 class="app-row__title text-truncate">{{ app.name }}</h4>
      <div class="app-row__specs">
        <span>{{ app.artistName }}</span>
      </div>
    </div>

    <div class="col col-auto app-row__btn-wrapper">
      <InstlBtn :app="app"/>
    </div>

  </div>
</template>

<script>
import InstlBtn from '@/components/InstlBtn.vue'

export default {
  props: {
    app: Object,
    showBottomSeparator: Boolean
  },
  components: {
    InstlBtn
  }
}
</script>

<style lang="scss">
.app-row {
  $root: &;
  padding: 15px;

  &__img {
    width: 64px;
    border-radius: 15px;
    border: 1px solid #CDCED2;
  }

  &__info-wrapper {
    &.col {
      padding: 0 15px;
    }
  }

  &__title {
    max-width: 220px;
    
    @media (min-width: 1200px) {
      max-width: 140px;
    }

    @media (min-width: 1400px) {
      max-width: 200px;
    }
  }

  &__details {
    margin-bottom: 15px;
  }

  &__specs {
    span {
      display: inline-block;
      margin: 0 5px;
      color: #CDCED2;
    }
  }

  &__btn-wrapper {
    display: flex;
    align-items: center;
  }

  &--has-separator {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: calc(100% - 109px);
      height: 1px;
      bottom: 1px;
      left: 94px;
      background: #CDCED2;
    }
  }
}
</style>
