import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload'
import Notifications from 'vue-notification'
import VueAnalytics from 'vue-analytics'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

firebase.initializeApp({
  apiKey: 'AIzaSyCjATSCk07av6kDJOhiaUt8VdnuayGDLjQ',
  projectId: 'instl-app-1b2c5',
  authDomain: "instl.app",
});

Vue.config.productionTip = false;
Vue.use(VueLazyload);
Vue.use(Notifications);
Vue.use(VueAnalytics, {
  id: 'UA-39491283-14',
  router
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

function handleFirstTab(e) {
  if (e.keyCode === 9) { // the "I am a keyboard user" key
      document.body.classList.add('user-is-tabbing');
      window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);
