import axios from 'axios';
let jsonpAdapter = require('axios-jsonp');

export default class iTunes {
  static get ContentType() {
    return {
      iOSApps: 'ios-apps',
      macApps: 'macos-apps'
    };
  }

  static get Genre() {
    return {
      all: 'all',
      games: 'games'
    };
  }

  static get Format() {
    return {
      json: 'json',
      xml: 'xml',
      rss: 'rss'
    };
  }

  static get Feed() {
    return {
      // newApps: 'new-apps-we-love',
      // newGames: 'new-games-we-love',
      topFree: 'top-free',
      // topFreeiPad: 'top-free-ipad',
      // topGrossing: 'top-grossing',
      // topGrossingiPad: 'top-grossing-ipad',
      topPaid: 'top-paid',
    };
  }

  static get MediaType() {
    return {
      movie: 'movie', 
      podcast: 'podcast', 
      music: 'music', 
      musicVideo: 'musicVideo', 
      audiobook: 'audiobook', 
      shortFilm: 'shortFilm', 
      tvShow: 'tvShow', 
      software: 'software', 
      ebook: 'ebook', 
      all: 'all'
    };
  }

  static get EntityType() {
    return {
      software: 'software', 
      iPadSoftware: 'iPadSoftware', 
      macSoftware: 'macSoftware'
    }
  }

  get storeCountry() {
    let countryJson = localStorage.getItem('store-country');
    let country;
    try {
      country = JSON.parse(countryJson);
    }catch(e) { 
      country = null;
    }

    if (country) {
      return country;
    }else {
      const def = this._defaultStoreCountry();
      this.storeCountry = def;
      return def;
    }
  }

  set storeCountry(country) {
    if (!localStorage.getItem('store-country') || this.storeCountry.code !== country.code) {
      localStorage.setItem('store-country', JSON.stringify(country));
      this._clearFeedCache();
    }
  }

  _defaultStoreCountry() {
    return {code: 'us', name: 'United States', flag: '/imgs/flags/us-60.png'};
  }

  _cachedFeed(feedId) {
    const expireDateString = localStorage.getItem('feed-' + feedId + '-expires');
    const expireDate = new Date(expireDateString);
    const now = new Date();
    
    if (now < expireDate) {
      try {
        const feedJson = localStorage.getItem('feed-' + feedId);
        return JSON.parse(feedJson);
      }catch (e) {
        return null;
      }
    }else {
      return null;
    }
  }

  _cacheFeed(feed) {
    try {
      const feedJson = JSON.stringify(feed);
      const now = new Date();
      const expireDate = new Date(now.getTime() + 3600 * 12 * 1000);
      localStorage.setItem('feed-' + feed.id, feedJson);
      localStorage.setItem('feed-' + feed.id + '-expires', expireDate.toISOString());
    }catch (e) {
      // eslint-disable-next-line 
    }
  }

  _clearFeedCache() {
    let toDelete = [];
    for (let i = 0; i < localStorage.length; ++i) {
      const key = localStorage.key(i);
      if (key.startsWith('feed-')) {
        toDelete.push(key);
      }
    }
    toDelete.forEach(k => localStorage.removeItem(k));
  }

  _buildRssUrl(country, feed, contentType, genre, explicit=true, limit=50, format=iTunes.Format.json) {
    // const isExplicit = explicit ? 'explicit' : 'non-explicit';
    const feedPath = `${country}/apps/${feed}/${limit}/apps.${format}`;
    return `${feedPath}`;
  }

  getFeed(feed, country=null, contentType=iTunes.ContentType.iOSApps, genre=iTunes.Genre.all) {
    const proxy = 'https://us-central1-instl-app-1b2c5.cloudfunctions.net/fetchAppStoreFeed?feed=';
    const url = this._buildRssUrl(country ? country : this.storeCountry.code, feed, contentType, genre);
    const feedCached = this._cachedFeed(feed);

    if (feedCached) {
      return Promise.resolve(feedCached);
    }else {
      return axios
        .get(proxy + url)
        .then((res) => {
          return {
            id: feed,
            title: res.data.feed.title,
            items: res.data.feed.results
          };
        })
        .then((feed) => {
          this._cacheFeed(feed);
          return feed;
        });
    }
  }

  search(query, country=null, media=iTunes.MediaType.software, entity=iTunes.EntityType.software, limit=50) {
    const url = 'https://itunes.apple.com/search';

    return axios({
      url: url,
      params: {
        term: query,
        country: country ? country : this.storeCountry.code, 
        media: media, 
        entity: entity, 
        limit: limit
      },
      adapter: jsonpAdapter
    }).then((res) => {
      console.log(res);
      return res.data.results;
    });
  }
}