import platform from 'platform';
import { Base64 } from 'js-base64';
import firebase from 'firebase/app';
import dockerNames from 'docker-names';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/auth';


export default class InstlCore {
  static getInstance() {
    if (!InstlCore._core) {
      InstlCore._core = new InstlCore();
    }
    return InstlCore._core;
  }

  get _functions() {
    if (!this._firebaseFunctions) {
      this._firebaseFunctions = firebase.functions();
    }
    return this._firebaseFunctions;
  }

  get _storage() {
    return window.localStorage;
  }

  get clientInfo() {
    const data = this._storage.getItem('clientInfo');
    if (!data) return null;

    try {
      return JSON.parse(data);
    }catch (e) {
      return null;
    }
  }

  set clientInfo(clientInfo) {
    try {
      this._storage.setItem('clientInfo', (!clientInfo) ? null : JSON.stringify(clientInfo));
    }catch (e) {
      console.error('LocalStorage not available')
    }
  }

  get cachedAuthToken() {
    const expireDateString = sessionStorage.getItem('device-auth-token-expires');
    
    if (!expireDateString) {
      return null;
    }
    
    const expireDate = new Date(expireDateString);
    const now = new Date();
    
    if (now < expireDate) {
      return sessionStorage.getItem('device-auth-token');
    }else {
      return null;
    }
  }

  set cachedAuthToken(token) {
    try {
      const now = new Date();
      const expireDate = new Date(now.getTime() + 900 * 1000);
      sessionStorage.setItem('device-auth-token', token);
      sessionStorage.setItem('device-auth-token-expires', expireDate.toISOString());
    }catch (e) {
      // eslint-disable-next-line 
    }
  }

  _authenticate() {
    const auth = firebase.auth();

    return new Promise((resolve, reject) => {
      if (!auth.currentUser) {
        auth.onAuthStateChanged((user) => {
          if (user) {
            resolve(user);
          }else {
            auth.signInAnonymously()
              .then((auth) => {
                resolve(auth.user);
              })
              .catch((e) => {
                reject(e);
              });
          }
        });
      }else {
        resolve(auth.currentUser);
      }
    });
  }

  _generateClientInfo() {
    return {
      name: dockerNames.getRandomName(),
      platform: `${platform.name} ${platform.version} (${platform.os})`
    };
  }

  createDeviceAuthToken() {
    return this._authenticate()
      .then((/*user*/) => {
        return firebase.functions()
          .httpsCallable('createDeviceToken')
          .call(this)
          .then(data => data.data);
    });
  }

  registerClient() {
    if (this.clientInfo && this.clientInfo.id) {
      return Promise.resolve(this.clientInfo);
    } else {
      const clientInfo = this._generateClientInfo();

      return this._authenticate()
        .then((user) => {
          return firebase.firestore()
              .collection(`users/${user.uid}/clients`)
              .add(clientInfo);
        })
        .then((doc) => {
          clientInfo.id = doc.id;
          this.clientInfo = clientInfo;

          return clientInfo;
        });
    }
  }

  generateQrContent() {
    if (!this.clientInfo) return Promise.reject();
    if (this.cachedAuthToken) {
      return Promise.resolve(this.cachedAuthToken);
    }
    
    return this.createDeviceAuthToken()
      .then((token) => {
        const payload = {
          version: '1',
          client: this.clientInfo,
          token: token
        };

        const encodedToken = Base64.encode(JSON.stringify(payload));
        this.cachedAuthToken = encodedToken;
        // console.log(encodedToken);
        return encodedToken;
      });
  }

  installApp(appId, appName, appIcon) {
    if (!(this.clientInfo && this.clientInfo.id) || !firebase.auth().currentUser) {
      return Promise.reject(new Error('This browser is not ready to install yet. Go to the <a href="/bind">bind device page</a> for the initial setup.'));
    }
    
    const uid = firebase.auth().currentUser.uid;
    const wishlistItem = {
      app: {
        id: parseInt(appId),
        name: appName,
        icon: appIcon,
      },
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      source: {
        type: 'client',
        id: this.clientInfo.id
      }
    };

    return firebase.firestore()
        .collection(`users/${uid}/wishlist`)
        .doc(`${appId}`)
        .set(wishlistItem)
  }
}