<template>
  <ul class="app-list">
    <li v-for="(app, index) in apps" :key="app.id">
      <AppRow :app="app" :showBottomSeparator="index < apps.length - 1" />
    </li>
  </ul>
</template>

<script>
import AppRow from '@/components/AppRow.vue'

export default {
  name: 'AppList',
  components: {
    AppRow
  },
  props: {
    apps: Array
  }
}
</script>

<style lang="scss">
.app-list {
  margin: 0;
  padding: 0;
  background: #ffffff;
  border-radius: 4px;

  li {
    list-style: none;
  }
} 
</style>
